import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom/client";
import {App} from "./App";
import {setFavicon} from "./config/global.config";
import {Monitoring} from "./config/monitoring.config";

// Initiate Sentry && Datadog (for Stage and Prod)
Monitoring.init();

//Set Favicon
setFavicon();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
